window.opq = (window.opq || {})

opq.Flowchart = (function Burger($) {

  'use strict';
  /**
   * jQuery elements.
   * @private
   */
  var $reminderOpen = $('[data-trigger-reminder-open]'),
      $reminderContent = $('[data-trigger-reminder-content]');

  /**
   * Has the application been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    $reminderOpen.on('click', function() {
      $(this).stop().toggleClass('is-open');

      toggleReminder();
    });

    inited = true;

    // return success
    return true;

  };


  var toggleReminder = function() {
    $reminderContent.stop().slideToggle('medium', function() {
      if ($(this).is(':visible'))
        $(this).css('display','flex');
    });
  };

  // Expose public methods & properties
  return {
    init: init,
  };

})(jQuery);
