window.opq = (window.opq || {})

opq.Guide = (function Guide($) {

  'use strict';

  /**
   * Has the application been initialized?
   * @private
   */
  var inited = false;

  var $sideMenu = $('.js-sidemenu'),
    $content = $('.js-guideContent'),
    $loader = $('.js-loader'),
    $libButton = $('[data-trigger-library]'),
    $libTextButton = $('[data-trigger-text-library]'),
    $standardButton = $('[data-trigger-standard]'),
    $fixed = $('.js-fixed'),
    $scrollTop = $('[data-trigger-to-top]');

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    $scrollTop.on('click', function(e){
      e.preventDefault();

        $('html, body').animate({
          scrollTop: 0
        }, 'slow');
    });

    if(opq.App.checkViewport() == 'large'){

      $fixed.scrollToFixed({
        marginTop: 20,
        removeOffsets: true,
        limit: $('footer').offset().top - $fixed.outerHeight(true),
        fixed: function () {

          /*Fixed offset to right*/
          $(this).next().stop().css('max-width', 'calc(100% - 59%)').css('float', 'left').addClass('scroll-spacer');
        }
      });
    }

    $sideMenu.on('click', function (e) {

      e.preventDefault();
      if (!$(this).hasClass('isAjax')) {
        switchPage($(this));
      }
    });

    $libButton.on('click', function (e) {
      e.preventDefault();

      if (!$(this).hasClass('is-active')) {
        switchLibrary();

        $standardButton.removeClass('is-active');
        $(this).addClass('is-active');
        $('.guide-wrapper').addClass('library-page');
      }
    });

    $standardButton.on('click', function (e) {
      e.preventDefault();

      if (!$(this).hasClass('is-active')) {
        switchStandard();

        $libButton.removeClass('is-active');
        $(this).addClass('is-active');
        $('.guide-wrapper').removeClass('library-page');
      }
    });

    $(document).on('click', '.lib-text-btn', function (e) {
      $libButton.click();
    });

    inited = true;

    // return success
    return true;

  };

  var switchPage = function (e) {

    $.ajax({
      type: 'GET',
      url: e.find('.js-switchPage').attr('href'),
      beforeSend: function () {

        $loader.fadeIn();

        $content.fadeOut(function () {

          $(this).empty();
        });

      },
      success: function (response) {


        $loader.delay(500).fadeOut(function () {
          $content.append(response);
          $content.fadeIn('slow');


          $('.print-btn').on('click', function (e) {

            e.preventDefault();
            window.print();
          });
        });


        $('.js-std-btn').attr('data-entryurl', e.find('.js-switchPage').attr('href'));

        history.pushState(response, '', e.find('.js-switchPage').attr('href'));

        $('.isAjax').not($(e)).each(function () {
          $(this).removeClass('isAjax');
        });

        e.addClass('isAjax');

        //$fixed.css('top', 'auto');

        if (opq.App.checkViewport() == 'small') {

          var bottom = $('header').outerHeight(true) + $('.hero-banner').outerHeight(true) + $('.side-menu-wrapper').outerHeight(true) + 30;
        } else {

          var bottom = $('header').outerHeight(true) + $('.hero-banner').outerHeight(true);

        }

        opq.Scroll.getSectionArray();
        $('html,body').animate({scrollTop: bottom}, 300, function() {
          if(opq.App.checkViewport() == 'large') {

            $fixed.scrollToFixed({
              marginTop: 20,
              removeOffsets: true,
              limit: $('footer').offset().top - $fixed.outerHeight(true),
              fixed: function () {

                /*Fixed offset to right*/
                $(this).next().stop().css('max-width', 'calc(100% - 59%)').css('float', 'left').addClass('scroll-spacer');
              }
            });
          }
        });




      }
    });

  };

  var switchLibrary = function () {

    var data = $libButton.data('entry');

    var slug = data.type;
    var typeNumber = data.number;
    var typeName = data.name;

    $content.empty();
    $loader.fadeIn();
    $('.side-menu-wrapper').fadeOut();

    setTimeout(function () {
      $content.load("/_partials/guides-library.twig?entryType=" + slug + "&typeNumber=" + typeNumber + "&typeName=" + typeName, function () {

        if (opq.App.checkViewport() == 'small') {

          var bottom = $('header').outerHeight(true) + $('.hero-banner').outerHeight(true) + $('.side-menu-wrapper').outerHeight(true) + 30;
        } else {

          var bottom = $('header').outerHeight(true) + $('.hero-banner').outerHeight(true);
        }

        opq.Scroll.getSectionArray();
        $('html,body').animate({scrollTop: bottom}, 300);
        $loader.fadeOut();
      }, 790);
    });
  };

  var switchStandard = function () {

    $content.empty();
    $loader.fadeIn();
    $('.side-menu-wrapper').fadeOut();

    setTimeout(function () {
      $content.load($standardButton.attr('data-entryurl'), function () {
        $loader.fadeOut();
        $('.side-menu-wrapper').fadeIn();
      }, 790);
    });
  };


  // Expose public methods & properties
  return {
    init: init,
  };

})(jQuery);
