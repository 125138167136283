window.opq = (window.opq || {})

opq.Scroll = (function Scroll($) {

  'use strict';

  /**
   * Has the application been initialized?
   * @private
   */
  var inited = false;

  var $sidemenu = $('.js-sidemenu');
  var $firstLink = $('.js-firstLink');
  var $lastLink = $('.js-lastLink');


  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }


    /**
     * Scroll to correct guide section on click
     */
    $($sidemenu).find('.side-menu-link').on('click', function (event) {

      currentSection($(this));
      event.preventDefault();
      $('html,body').animate({scrollTop: $(this.hash).offset().top}, 500);
    });

    /**
     * Initialize section array on load
     */
    getSectionArray();


    /**
     * Highlight correct section according to window scroll position
     */
    $(window).on('scroll', function () {

      scrollSection();
    });


    inited = true;

    // return success
    return true;

  };

  /**
   * Check for the current guide page
   */
  var currentSection = function (e) {

    e.addClass('current');

    $sidemenu.find('.side-menu-link').each(function () {

      if ($(this).not(e).hasClass('current')) {

        $(this).not(e).removeClass('current');
      }
    });
  };

  /**
   * Fill available section according to
   * current guide page into array
   */
  var getSectionArray = function () {

    var aArray = []; // create the empty aArray
    var aChildren = $(".js-sidemenu.opened .side-menu-list li").children();


    for (var i = 0; i < aChildren.length; i++) {
      var aChild = aChildren[i];
      var ahref = $(aChild).attr('href');
      aArray.push(ahref);
    }

    return aArray;
  };

  /**
   * Scroll to correct guide section function
   */

  var scrollSection = function () {

    var $winPos = $(window).scrollTop() + 30;
    var $winHeight = $(window).height();

    var $footerHeight = $('footer').outerHeight() + $('.notification').outerHeight();
    var $docHeight = $(document).height() - $footerHeight;

    for (var i = 0; i < getSectionArray().length; i++) {
      var $sectionID = getSectionArray()[i];
      if($($sectionID).length){
        var $divPos = $($sectionID).offset().top;
      }else {
        var $divPos = 0;
      }
      var $divHeight = $($sectionID).height();

      if (
        $winPos >= $divPos &&
        $winPos <= ($divPos + $divHeight) &&
        ($winPos + $winHeight) < $docHeight
      ) {

        $("a[href='" + $sectionID + "']").addClass("current");

      } else {

        $("a[href='" + $sectionID + "']").removeClass("current");
      }
    }

    //DETECT PRACTICE

    var $practice = $('.js-annexe');

    if ($practice.length > 0) {
      if (
        $winPos >= $practice.offset().top &&
        $winPos < $practice.offset().top + $practice.outerHeight(true)
      ) {

        $firstLink.addClass("current");
      } else {
        $firstLink.removeClass("current");
      }
    }

    //DETECT LIBRARY
    if ($('.guide-content').length > 0) {
      var $contentHeight = $('.guide-content').outerHeight(true) + $('.guide-content').offset().top;

      if (
        $winPos + $winHeight >= $docHeight ||
        $winPos >= $contentHeight
      ) {
        if (!$lastLink.hasClass("current")) {

          var navActiveCurrent = $(".current").attr("href");

          $("a[href='" + navActiveCurrent + "']").removeClass("current");

          $lastLink.addClass("current");
        }
      } else {
        $lastLink.removeClass("current");
      }
    }
  };
  // Expose public methods & properties
  return {
    init: init,
    getSectionArray: getSectionArray,
  };


})(jQuery);


