window.opq = (window.opq || {})

opq.Accordeon = (function Accordeon($) {

    'use strict';
    /**
     * jQuery elements.
     * @private
     */

    var $sidemenu = $('.js-sidemenu');
    var $accordeon = $('.js-accordeon');

    /**
     * Has the application been initialized?
     * @private
     */
    var inited = false;

    /**
     * Initializes the class.
     * @public
     */
    var init = function () {

        // Abort if already initialized
        if (inited) {
            return false;
        }

        inited = true;

        $('body').on('click', '.js-annexe', function(){

            if($(this).hasClass('opened')){

                $(this).removeClass('opened').find('.accordeon-wrapper').slideUp();
            }else {

              $(this).addClass('opened').find('.accordeon-wrapper').slideDown();
            }
        });

        $sidemenu.on('click', function(){

            accordeonMenu($(this));
        });


        accordeonFirstCheck();

        // return success
        return true;

    };

    var accordeonMenu = function(e){


      if(e.hasClass('opened')){

        e.removeClass('opened').children().find($accordeon).slideUp();
      }else{

        e.addClass('opened').children().find($accordeon).slideDown();
      }

        $sidemenu.each(function(){

            if($(this).not(e).hasClass('opened')){

                $(this).not(e).removeClass('opened').children().find($accordeon).slideUp();
            }
        });

      $accordeon.on('click',function(e){

        e.stopPropagation();
      });
    };

    var accordeonFirstCheck = function(){

      $sidemenu.each(function(){

        if($(this).hasClass('opened')){

          $(this).find($accordeon).slideDown();
        }
      });

      $accordeon.on('click',function(e){

        e.stopPropagation();
      });
    };

    // Expose public methods & properties
    return {
        init: init,
    };

})(jQuery);
