window.opq = (window.opq || {});

/**
 * Utilities for interacting with the application.
 * @class App
 * @static
 */
opq.App = (function App($) {

  'use strict';

  /**
   * Has the application been initialized?
   * @private
   */
  var inited = false;

  var $fixed = $('.js-fixed');
  var $reminder = $('.js-reminder');
  var $columns = $('.js-column');
  var $scroller = $('[data-trigger-scroll-circuit]');

  /**
   * Application config defaults.
   * @private
   * @param {Object} config.env     Current server environment
   * @param {Object} config.locale  Current locale short code
   */
  var config = {
    env: 'local',
    locale: 'fr',
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function (options) {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Store application settings
    options = (options || {});

    if (options.env) {
      config.env = options.env;
    }
    if (options.locale) {
      config.locale = options.locale;
    }


    if (typeof opq.Burger === 'object') {
      opq.Burger.init();
    }

    if (typeof opq.Sidebar === 'object') {
      opq.Sidebar.init();
    }

    if (typeof opq.Guide === 'object') {
      opq.Guide.init();
    }

    if (typeof opq.Accordeon === 'object') {
      opq.Accordeon.init();
    }

    if (typeof opq.Scroll === 'object') {
      opq.Scroll.init();
    }

    if (typeof opq.Form === 'object') {
      opq.Form.init();
    }

    if (typeof opq.Flowchart === 'object') {
      opq.Flowchart.init();
    }

    if (typeof opq.Search === 'object') {
      opq.Search.init();
    }

    /*if (typeof opq.Menu === 'object') {
      opq.Menu.init();
    }*/

    $scroller.on('click', function(e) {
      e.preventDefault();
      $('html,body').animate({scrollTop: $('#flowchart').offset().top - 30}, 300)
    });

    checkViewport();
/*
    if (checkViewport() == 'large') {

      $fixed.scrollToFixed({
        marginTop: 20,
        removeOffsets: true,
        limit : $('footer').offset().top - $fixed.outerHeight(true),
        fixed: function () {

          /!*Fixed offset to right*!/
          $(this).next().stop().css('max-width', 'calc(100% - 59%)').css('float', 'left').addClass('scroll-spacer');
        }
      });

    }*/

    $('.print-btn').on('click',function(e){

      e.preventDefault();
      window.print();
    });

    // Return success
    return true;

  };

  /**
   * Check Viewport to enable certain callbacks
   * @private
   */
  var checkViewport = function () {
    var width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    if (width < 768) {

      return 'small';
    } else {

      return 'large';
    }
  };


  /**
   * Getter for application config.
   * @public
   */
  var getConfig = function (option) {
    return config[option] ? config[option] : false;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init: init,
    getConfig: getConfig,
    checkViewport: checkViewport,
  };

})(jQuery);
