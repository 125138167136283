window.opq = (window.opq || {})

opq.Search = (function Sidebar($) {

  'use strict';
  /**
   * jQuery elements.
   * @private
   */

  var $trigger = $('[data-search-trigger]'),
      $container = $('[data-search-container]'),
      $select = $('[data-search-select]');

  /**
   * Has the application been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    $trigger.not('.is-disabled').on('click',function(e){
      e.preventDefault();
      switchTabs($(this));
    });

    $select.on('change',function(){
      mobileTabs($(this));
    });

    // return success
    return true;

  };

  var switchTabs = function(button){

    var targetClass = button.data('search-type');

    $trigger.removeClass('is-active');
    button.addClass('is-active');

    $container.find('.search-results').removeClass('is-active');
    $container.find('.' + targetClass + '-results').addClass('is-active');

  };

  var mobileTabs = function(select){

    var targetClass = select.val();

    $trigger.removeClass('is-active');

    $.each($trigger, function(i, val){

      if($(val).data('search-type') == targetClass) {
        $(val).addClass('is-active');
      }
      else {
        $(val).removeClass('is-active');
      }

    });

    $container.find('.search-results').removeClass('is-active');
    $container.find('.' + targetClass + '-results').addClass('is-active');

    console.log(targetClass);
  };


  // Expose public methods & properties
  return {
    init: init,
  };

})(jQuery);
