window.opq = (window.opq || {});

/**
 * Initialize sidebar navigation.
 * @class Menu
 * @static
 */
opq.Menu = (function Menu($) {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * jQuery elements.
   * @private
   */
  var $topOffset   = $('.js-guideContent');
  var $menu     = $('.scroll-wrapper');
  var $content  = $('.guide-wrapper');
  var $spacer   = $('.content-spacer');

  /**
   * Initializes the class.
   * @public
   */
  var init = function() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Initialize components
    if ($menu.length) {
      initSticky();
    }

    $(window).on('resize', function() {

      if ($menu.length) {
        initSticky();
      }

    });

    // Return success
    return true;

  };

  /**
   * Initialize sidebar sticky menu that follow the content and clip top or bottom.
   * @private
   */
  var initSticky = function(setInitialTop) {

    // Get initial scroll top
    var scrollTop = $(window).scrollTop();

    // Get window height
    var windowHeight = $(window).height();

    // Get menu height
    var menuHeight = $menu.outerHeight(true);

    // Get menu top
    var menuTop = $topOffset.offset().top;
    var top = menuTop;

    // Get menu maximum top
    var menuTopMax = windowHeight - menuHeight;

    // Menu is longer than window

    if ((menuTop + menuHeight) > windowHeight) {
      // Set initial menu top
      if (setInitialTop !== false) {

        var newTop = menuTop - scrollTop;

        if (newTop > menuTopMax) {
          top = newTop;
        } else {
          top = menuTopMax;
        }

        $menu.css('top', top);
      }

      // Set content spacer height if menu is longer than content
      var contentHeight = $content.outerHeight(true);

      if (contentHeight < menuHeight) {
        $spacer.height(menuHeight - contentHeight);
      }

      // Change menu top on scroll
      $(window).on('scroll', function () {

        var currentTop = $(this).scrollTop();
        var scrollDiff = scrollTop - currentTop;
        var newTop = top + scrollDiff;

        // Scroll direction
        if (currentTop > scrollTop) {

          // Scroll down
          if (newTop < menuTopMax) {
            top = menuTopMax;
          } else {
            top = newTop;
          }

        } else {

          // Scroll up
          if (newTop > menuTop) {
            top = menuTop;
          } else {
            top = newTop;
          }

        }

        $menu.css('top', top);
        scrollTop = currentTop;

      });


    }

  };

  // Expose public methods & properties
  return {
    init: init
  };

}(jQuery));
