window.opq = (window.opq || {})

opq.Form = (function Sidebar($) {

  'use strict';
  /**
   * jQuery elements.
   * @private
   */

  var $trigger = $('[data-form-trigger]');
  var $nav = $('.header-nav');
  var $form = $('.search-form');
  var $overlay = $('.form-overlay');
  var $inputField = $('[data-trigger-query]');

  /**
   * Has the application been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    $trigger.on('click', function (e) {
      e.stopPropagation();
      return $('header').hasClass('is-scroll-active') ? closeForm() : openForm();

    });

    $('body').on('click', function (e) {
      //e.stopPropagation();

      if ($('header').hasClass('is-scroll-active')) {
        closeForm();
      }
    });

    $('[data-trigger-query], .menu-links, header').on('click', function (e) {
      e.stopPropagation();
    });

    inited = true;

    // return success
    return true;

  };

  var openForm = function () {

    var width = $(window).width();
    $('body').addClass('no-scroll');
    $inputField.focus();

    if (width > 1101) {
      $('header').addClass('is-scroll-active');
      $overlay.fadeIn();
      $inputField.focus();
    }
    else if (width > 768) {
      $nav.fadeOut(200, function () {
        $('header').addClass('is-scroll-active');
        $overlay.fadeIn();
        $inputField.focus();
      });
    }
    else {
      $('header').addClass('is-scroll-active');

      $overlay.fadeIn();
      $inputField.focus();
    };


  };

  var closeForm = function () {

    var width = $(window).width();

    $('body').removeClass('no-scroll');
    $('header').removeClass('is-scroll-active');

    $form.on('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd',
      function () {
        if (width > 768 && width < 1101) {
          $nav.fadeIn(200);
        }

        $form.off('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd');
      });

      $overlay.fadeOut(200);
  };


  // Expose public methods & properties
  return {
    init: init,
  };

})(jQuery);
