window.opq = (window.opq || {})

opq.Sidebar = (function Sidebar($) {

    'use strict';
    /**
     * jQuery elements.
     * @private
     */
    var $close = $('.js-close');
    var $open = $('.js-open');
    var $alertWrapper = $('.js-alert');
    var $noClick = $('.js-noClick');

    /**
     * Has the application been initialized?
     * @private
     */
    var inited = false;

    /**
     * Initializes the class.
     * @public
     */
    var init = function () {

        // Abort if already initialized
        if (inited) {
            return false;
        }


        /**
         * Call to open Alert
         * @private
         */
        $open.on('click', function(){

            openAlert();
        });

        /**
         * Call to close Alert
         */
        $close.on('click', function(){

            closeAlert();
        });

        /**
         * Prevent Alert closure when clicking on it
         */
        $noClick.on('click',function(e){

            e.stopPropagation();
        });

        inited = true;

        // return success
        return true;

    };


    /**
     * Opens the Alert Sidebar
     * @private
     */
    var openAlert = function (){

        if ($alertWrapper.hasClass('opened')) {
            $alertWrapper.removeClass('opened');
            $('body').removeClass('no-scroll alert-open');
        }
        else {
            $alertWrapper.addClass('opened');
            $('body').addClass('no-scroll alert-open');
        }
    };

    /**
     * Closes the Alert Sidebar
     * @private
     */
    var closeAlert = function (){

        $alertWrapper.removeClass('opened');

        $('body').removeClass('no-scroll alert-open');

    };



    // Expose public methods & properties
    return {
        init: init,
    };

})(jQuery);
